





























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { File, MimeType, Type } from '../types';
import AppTable from '@/components/AppTable.vue';
import FileDownload from './FileDownload.vue';

const FileManagement = namespace('fileManagement');

@Component({
  components: { FileDownload, AppTable }
})
export default class FileManagementPlugin extends Vue {
  @Prop({ type: Array, default: () => [] }) files!: File | undefined;
  @Prop({ type: Object }) renameFile!: File | undefined;
  @Prop({ type: Object }) moveFile!: File | undefined;
  @Prop({ type: Object }) deleteFile!: File | undefined;
  @Prop({ type: Object }) viewFile!: File | undefined;
  @Prop({ type: String }) search!: string;
  @Prop({ type: String }) folder!: string;
  @Prop({ type: Boolean, default: false }) loading!: boolean;

  get rename() {
    return this.renameFile;
  }

  set rename(value) {
    this.$emit('update:renameFile', value);
  }

  get move() {
    return this.moveFile;
  }

  set move(value) {
    this.$emit('update:moveFile', value);
  }

  get deleted() {
    return this.deleteFile;
  }

  set deleted(value) {
    this.$emit('update:deleteFile', value);
  }

  get view() {
    return this.viewFile;
  }

  set view(value) {
    this.$emit('update:viewFile', value);
  }

  supportedViews: MimeType[] = [
    MimeType.JPG,
    MimeType.PDF,
    MimeType.PNG
  ];

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.filename'), value: 'filename' },
      { align: 'right', text: this.$i18n.t('common.created'), value: 'created' },
      { align: 'right', text: this.$i18n.t('common.actions'), value: null, sortable: false },
    ];
  }

  convertable(file: File) {
    return file.type === Type.local && file.mimeType === MimeType.PDF;
  }
}
