








































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CreateFolder, Folder } from '../types';
import { CREATE_FOLDER } from '@/modules/fileManagement/store';
import { ApiResponse } from '@/components/types';
import uuid from 'uuid/v4';

const FileManagement = namespace('fileManagement');

@Component({})
export default class CreateSubFolderDialog extends Vue {
  @Prop({ type: Object }) value!: Folder | null;
  @Prop({ type: String, required: true }) conservatorship!: string;

  @FileManagement.Action(CREATE_FOLDER) createSubFolder!: (command: CreateFolder) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;
  loading: boolean = false;

  folder: CreateFolder = {
    conservatorship_id: this.conservatorship,
    parent_id: '',
    folder_id: '',
    name: ''
  };

  @Watch('value')
  watchFile(file: File) {
    if (!file) return;

    this.init();

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  clear(observer: any) {
    this.dialog = false;

    setTimeout(() => {
      observer.reset();
      this.folder = {
        conservatorship_id: '',
        parent_id: '',
        folder_id: '',
        name: ''
      };
      this.error = null;
      this.$emit('input', null);
    }, 200);
  }

  init() {
    if (!this.value) return;

    this.folder = {
      parent_id: this.value.id,
      conservatorship_id: this.conservatorship,
      folder_id: uuid(),
      name: ''
    };
  }

  onSuccess() {
    this.dialog = false;

    if (this.value) {
      this.folder = {
        conservatorship_id: this.conservatorship,
        parent_id: this.value.id,
        folder_id: uuid(),
        name: ''
      };
    }

    this.$emit('input', null);
    this.$emit('success');
  }

  async save(): Promise<ApiResponse> {
    this.error = null;

    return this.createSubFolder(this.folder);
  }
}
