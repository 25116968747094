

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { File, Folder, MoveFile } from '../types';
import { ApiResponse } from '@/components/types';
import { MOVE_FILE } from '@/modules/fileManagement/store';

const FileManagement = namespace('fileManagement');

@Component({})
export default class MoveFileDialog extends Vue {
  @Prop({ type: Object }) value!: File | null;
  @Prop({ type: Array, required: true }) tree!: Folder[];
  @Prop({ type: String, required: false }) ignore?: string;

  @FileManagement.Action(MOVE_FILE) move!: (command: MoveFile) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  file: MoveFile = {
    file_id: '',
    folder_id: ''
  };

  get items() {
    return this.tree.filter((item) => item.id !== this.ignore)
  }

  get folder() {
    return [this.file.folder_id];
  }

  set folder(folder) {
    if (folder.length === 0) {
      this.file.folder_id = '';
      return;
    }

    this.file.folder_id = folder[folder.length - 1];
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.file = {
        file_id: '',
        folder_id: ''
      };

      this.error = null;
      this.$emit('input', null);

      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  @Watch('value')
  watchFile(file: File) {
    if (!file) return;

    this.init();

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  init() {
    if (!this.value) return;

    this.file = {
      file_id: this.value.id,
      folder_id: this.value.folderId
    };
  }

  onSuccess() {
    this.dialog = false;
    this.$emit('success');
  }

  async save(): Promise<ApiResponse> {
    this.error = null;

    if (!this.file.folder_id) return {};

    return this.move(this.file);
  }
}
