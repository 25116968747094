
















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Folder, MoveFolder } from '../types';
import { MOVE_FOLDER } from '@/modules/fileManagement/store';
import { ApiResponse } from '@/components/types';

const FileManagement = namespace('fileManagement');

@Component({})
export default class MoveFolderDialog extends Vue {
  @Prop({ type: Object }) value!: Folder | null;
  @Prop({ type: Array, required: true }) tree!: Folder[];
  @Prop({ type: String, required: false }) ignore?: string;

  @FileManagement.Action(MOVE_FOLDER) move!: (command: MoveFolder) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  folder: MoveFolder = {
    folder_id: '',
    parent_id: ''
  };

  get items() {
    return this.tree.filter((item) => item.id !== this.ignore)
  }

  get parent() {
    if (!this.folder.parent_id) {
      return [];
    }

    return [this.folder.parent_id];
  }

  set parent(folder: string[]) {
    if (folder.length === 0) {
      this.folder.parent_id = null;
      return;
    }

    this.folder.parent_id = folder[folder.length - 1];
  }

  get root() {
    return this.parent.length === 0;
  }

  set root(root: boolean) {
    if (root) {
      this.folder.parent_id = null;
    }
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.folder = {
        folder_id: '',
        parent_id: ''
      };

      this.error = null;
      this.$emit('input', null);

      // @ts-ignore
      this.$refs.form.clear();
    }, 200);
  }

  @Watch('value')
  watchFile(file: Folder) {
    if (!file) return;

    this.init();

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  init() {
    if (!this.value) return;

    this.folder = {
      folder_id: this.value.id,
      parent_id: this.value.parentId || null
    };
  }

  onSuccess() {
    this.dialog = false;
    this.$emit('success');
  }

  async save(): Promise<ApiResponse> {
    this.error = null;

    return this.move(this.folder);
  }
}
