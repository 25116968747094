






























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import {
  FETCH_FILES_BY_FOLDER,
  FETCH_INBOX_BY_CONSERVATORSHIP,
  FETCH_OUTBOX,
  FETCH_TREE_BY_CONSERVATORSHIP,
  GET_FILES_BY_FOLDER,
  GET_INBOX_BY_CONSERVATORSHIP,
  GET_OUTBOX_BY_CONSERVATORSHIP,
  GET_TREE_BY_CONSERVATORSHIP
} from '../store';
import { File, Folder } from '../types';
import AppTable from '@/components/AppTable.vue';
import format from '@/filter/dateFormat';
import CreateFolderDialog from '@/modules/fileManagement/components/CreateFolderDialog.vue';
import UploadFilesDialog from '@/modules/fileManagement/components/UploadFilesDialog.vue';
import ViewFileDialog from '@/modules/fileManagement/components/ViewFileDialog.vue';
import RenameFileDialog from '@/modules/fileManagement/components/RenameFileDialog.vue';
import RemoveFileDialog from '@/modules/fileManagement/components/RemoveFileDialog.vue';
import RenameFolderDialog from '@/modules/fileManagement/components/RenameFolderDialog.vue';
import RemoveFolderDialog from '@/modules/fileManagement/components/RemoveFolderDialog.vue';
import CreateSubFolderDialog from '@/modules/fileManagement/components/CreateSubFolderDialog.vue';
import MoveFileDialog from '@/modules/fileManagement/components/MoveFileDialog.vue';
import MoveFolderDialog from '@/modules/fileManagement/components/MoveFolderDialog.vue';
import FileDownload from '@/modules/fileManagement/components/FileDownload.vue';
import PluginTable from '@/modules/fileManagement/components/PluginTable.vue';
import PluginCards from '@/modules/fileManagement/components/PluginCards.vue';
import { routeToLocation } from '@/router';

const FileManagement = namespace('fileManagement');

@Component({
  components: { PluginCards, PluginTable, FileDownload, MoveFolderDialog, MoveFileDialog, CreateSubFolderDialog, RemoveFolderDialog, RenameFolderDialog, RemoveFileDialog, RenameFileDialog, ViewFileDialog, UploadFilesDialog, CreateFolderDialog, AppTable }
})
export default class FileManagementPlugin extends Vue {
  @FileManagement.Action(FETCH_TREE_BY_CONSERVATORSHIP) fetchFolder!: (conservatorship: string) => Promise<ApiResponse<Folder[]>>;
  @FileManagement.Getter(GET_TREE_BY_CONSERVATORSHIP) getTree!: (conservatorship: string) => Folder[];

  @FileManagement.Action(FETCH_FILES_BY_FOLDER) fetchFiles!: (folder: string) => Promise<ApiResponse<File[]>>;
  @FileManagement.Getter(GET_FILES_BY_FOLDER) getFiles!: (folder: string) => File[];

  @FileManagement.Action(FETCH_OUTBOX) fetchOutbox!: (conservatorship: string) => Promise<ApiResponse<File[]>>;
  @FileManagement.Getter(GET_OUTBOX_BY_CONSERVATORSHIP) getOutbox!: (conservatorship: string) => File[];

  @FileManagement.Action(FETCH_INBOX_BY_CONSERVATORSHIP) fetchInbox!: (conservatorship: string) => Promise<ApiResponse<File[]>>;
  @FileManagement.Getter(GET_INBOX_BY_CONSERVATORSHIP) getInbox!: (conservatorship: string) => File[];

  @Prop({ type: String }) conservatorship!: string;

  financeFolder = 'Finanz Belege';
  error: any = null;

  loadingFiles: boolean = false;
  loading: boolean = false;
  tree: Folder[] = [];
  active: string[] = [];
  search: string = '';

  uploadFolderId: string = '';
  viewFile: File | null = null;
  renameFile: File | null = null;
  deleteFile: File | null = null;
  moveFile: File | null = null;

  renameFolder: Folder | null = null;
  deleteFolder: Folder | null = null;
  createSubFolder: Folder | null = null;
  moveFolder: Folder | null = null;

  contextFolder: Folder | null = null;

  showMenu: boolean = false;
  x: number | null = null;
  y: number | null = null;

  show(event: MouseEvent, folder: Folder) {
    event.preventDefault();

    const isOpen = this.showMenu;

    this.showMenu = false;
    this.x = event.clientX;
    this.y = event.clientY;
    this.contextFolder = folder;

    if (!isOpen) {
      this.$nextTick(() => {
        this.showMenu = true;
      })
    }

    setTimeout(() => {
      this.showMenu = true;
    }, 100);
  }

  get items(): Array<Folder  & { color: string }> {
    const tree = (this.getTree(this.conservatorship) || [])
      .sort((a ,b) => {
        if (a.name === b.name) {
          return 0;
        }

        if (a.name === this.financeFolder) {
          return -1;
        }

        if (b.name === this.financeFolder) {
          return 1;
        }

        return a.name.localeCompare(b.name)
      })
    .map((folder): Folder & { color: string } => {
      if (folder.name === this.financeFolder) {
        return { ...folder, color: 'success--text' };
      }

      return { ...folder, color: '' };
    })

    return [
      { id: 'inbox', name: 'Posteingang', children: [], files: 0, color: 'warning--text' },
      { id: 'outbox', name: 'Postausgang', children: [], files: 0, color: 'warning--text' },
      ...tree
    ];
  }

  get financeFolderId(): string | null {
    return (this.getTree(this.conservatorship).find((folder) => folder.name === this.financeFolder) || { id: null }).id;
  }

  get rootTree(): Folder[] {
    return this.getTree(this.conservatorship);
  }

  get folder() {
    if (this.active.length === 0) return null;

    return this.active[0];
  }

  get files() {
    if (!this.folder) return [];

    if (this.folder === 'outbox') {
      return this.getOutbox(this.conservatorship).map((file: File) => ({
        ...file,
        createdTrans: format(file.created, 'dd.MM.yyyy HH:mm')
      }));
    }

    if (this.folder === 'inbox') {
      return this.getInbox(this.conservatorship).map((file: File) => ({
        ...file,
        createdTrans: format(file.created, 'dd.MM.yyyy HH:mm')
      }));
    }

    return this.getFiles(this.folder).map((file: File) => ({
      ...file,
      createdTrans: format(file.created, 'dd.MM.yyyy HH:mm')
    }));
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.filename'), value: 'filename' },
      { align: 'right', text: this.$i18n.t('common.created'), value: 'created' },
      { align: 'right', text: this.$i18n.t('common.actions'), value: null, sortable: false },
    ];
  }

  hasUpload(folder: Folder) {
    if (['outbox', 'inbox'].includes(folder.id)) {
      return false;
    }

    if (folder.id === this.financeFolderId) {
      return false;
    }

    if (folder.rootId === this.financeFolderId) {
      return false;
    }

    return true
  }

  changeable(folder: Folder) {
    if (['outbox', 'inbox'].includes(folder.id)) {
      return false;
    }

    if (folder.id === this.financeFolderId) {
      return false;
    }

    return true
  }

  isFinanceFolder(folder?: Folder): boolean {
    if (!folder) {
      return false;
    }

    return folder.id === this.financeFolderId || folder.rootId === this.financeFolderId;
  }

  get openFolder(): string {
    return this.$route.query['folder'] as string;
  }

  set openFolder(folder: string) {
    if (!folder) {
      const { folder, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, folder } })).catch(() => {});
  }

  @Watch('active', { deep: true })
  async loadFiles([folder]: string[]) {
    this.openFolder = folder;
    this.error = null;

    if (!folder) return;

    try {
      this.loadingFiles = true;

      if (folder === 'outbox') {
        await this.fetchOutbox(this.conservatorship);
      } else if (folder === 'inbox') {
        await this.fetchInbox(this.conservatorship);
      } else {
        await this.fetchFiles(folder);
      }
    } catch (error) {
      this.error = error
    } finally {
      this.loadingFiles = false
    }
  }

  async loadAll(folders: string[]) {
    await this.loadFiles(folders);

    return this.loadFolders(this.conservatorship);
  }

  @Watch('conservatorship', { immediate: true })
  async loadFolders(conservatorship: string) {
    this.loading = true;

    await this.fetchFolder(conservatorship);

    this.loading = false;

    if (this.active[0] !== this.openFolder) {
      this.active = [this.openFolder];
    }
  }

  uploadTo(folder: Folder) {
    this.uploadFolderId = folder.id;
    this.active.push(folder.id)
  }
}
