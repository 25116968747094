var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-table',{attrs:{"hide-default-footer":"","items":_vm.files,"items-per-page":-1,"search":_vm.search,"headers":_vm.headers,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:{ 'text-info': _vm.folder !== item.folderId }},[_c('td',[_vm._v(_vm._s(item.filename))]),_c('td',{staticClass:"text-right hidden-xs-only"},[_vm._v(_vm._s(item.createdTrans))]),_c('td',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(_vm.supportedViews.includes(item.mimeType))?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.view = item}}},on),[_c('v-icon',[_vm._v("search")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Datei Vorschau")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('file-download',_vm._g({attrs:{"file":item}},on))]}}],null,true)},[_c('span',[_vm._v("Datei herunterladen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.mdAndUp)?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.rename = item}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Datei umbenennen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.mdAndUp && item.type !== 'voucher')?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.move = item}}},on),[_c('v-icon',[_vm._v("reply")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Datei verschieben")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.mdAndUp)?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.deleted = item}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Datei löschen")])])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }