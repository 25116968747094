
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ErrorAlert from '@/components/ErrorAlert.vue';
import AppDropzone from '@/modules/fileManagement/components/AppDropzone.vue';
import { baseURL } from "@/modules/fileManagement/store";

@Component({
  components: {
    AppDropzone,
    ErrorAlert
  }
})
export default class UploadFilesDialog extends Vue {
  @Prop({ type: String, default: '' }) value!: string;

  error: any = null;
  dialog: boolean = false;
  close: Function = () => {};

  get additional(): { [option: string]: any } {
    return { 'folder_id': this.value }
  }

  get url(): string {
    return `${baseURL()}/file-service/file/upload`
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    this.error = null;
    this.$emit('input', '');
    this.close();
  }

  @Watch('value')
  watchFolder(folder: string) {
    if (!folder) return;

    this.dialog = true;
  }
}
