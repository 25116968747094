















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { CREATE_FOLDER } from '@/modules/fileManagement/store';
import { CreateFolder } from '@/modules/fileManagement/types';
import { createFolder } from '@/modules/fileManagement/model';

const FileManagement = namespace('fileManagement');

@Component({})
export default class CreateFolderDialog extends Vue {
  @FileManagement.Action(CREATE_FOLDER) createFolder!: (command: CreateFolder) => Promise<ApiResponse>;

  @Prop({ type: String, required: true }) conservatorship!: string;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;

  folder: CreateFolder = createFolder(this.conservatorship);

  clear(observer?: any) {
    this.dialog = false;

    setTimeout(() => {
      observer && observer.reset();
      this.folder = createFolder(this.conservatorship);
      this.error = null;
    }, 200);
  }

  save() {
    this.error = null;

    return this.createFolder(this.folder);
  }
}
